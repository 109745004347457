import React, { useState, useEffect } from "react"
import { useIntl  } from "gatsby-plugin-intl"
import SanitizedHTML from 'react-sanitized-html';
import disclaimerStyles from './disclaimer.module.scss'
import Modal from 'react-modal';
import SealLarge from '../images/seal_large'
import Language from "./language"
import { graphql, useStaticQuery } from "gatsby"

Modal.setAppElement('#___gatsby')
const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    maxHeight             : '90vh',
    maxWidth              : '90vw',
    fontSize              : '18px',
    backgroundColor       : '#ffffff'
  }
};
Modal.defaultStyles.overlay.backgroundColor = 'rgba(51, 51, 51, 0.77)';
Modal.defaultStyles.content.border = '0';
Modal.defaultStyles.content.borderRadius = '0';
Modal.defaultStyles.content.padding = '0';

export default function Disclaimer(props) {
  const [modalIsOpen, setIsOpen] = useState();
  const [agreed, setAgreed] = useState();
  const intl = useIntl();
  const disclaimerData =  useStaticQuery(graphql`
      query {
        pageTranslations {
          pageTranslations {
            disclaimer
            welcome
            disclaimerBody
            disclaimerButton
            exploreBody
            exploreHeader
            heroTitle
            heroBody
            heroButton
            glossary
            localization {
              culture
            }
          }
        }
      }
    `);

  function languageMap() {
    let pageTranslations = disclaimerData.pageTranslations.pageTranslations;
    const english = pageTranslations.filter(disclaimer => disclaimer.localization.culture === 'en-US');

    switch (intl.locale) {
      case 'es':
        return pageTranslations.filter(disclaimer => disclaimer.localization.culture === 'es') || english;
      case 'zh':
        return pageTranslations.filter(disclaimer => disclaimer.localization.culture === 'zh') || english;
      case 'ht':
        return pageTranslations.filter(disclaimer => disclaimer.localization.culture === 'fr-HT') || english;
      case 'pt':
        return pageTranslations.filter(disclaimer => disclaimer.localization.culture === 'pt') || english;
      case 'pa':
        return pageTranslations.filter(disclaimer => disclaimer.localization.culture === 'pa') || english;
      default: 
        return pageTranslations.filter(disclaimer => disclaimer.localization.culture === 'en-US');
    }
  }

  function  disclaimerMap() {
    return  languageMap().map(disclaim => ({
      disclaimer: disclaim.disclaimer,
      welcome: disclaim.welcome,
      body: disclaim.disclaimerBody,
      button: disclaim.disclaimerButton,
    }))[0]
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }

  function closeModal(){
    setIsOpen(false);
    setAgreed(true);
  }

  useEffect(() => {
      if (!document.cookie.split(';').some((item) => item.trim().startsWith('disclaimer='))) {
          //open modal and set cookie
          setIsOpen(true);
          if(agreed){
            document.cookie = `disclaimer=true; max-age=1800; samesite=strict; secure;`;
            setIsOpen(false);
          }
      }
  }, [setIsOpen, agreed]);
  
return (
    <Modal
    isOpen={modalIsOpen}
    onAfterOpen={afterOpenModal}
    onRequestClose={closeModal}
    shouldCloseOnOverlayClick={false}
    style={customStyles}
    contentLabel={intl.formatMessage({ id: "disclaimerHeader" })}
    >
        
        <div className="padding-5 margin-x-auto margin-y-0">
        <div className={disclaimerStyles.stackFlex}>
            <div className="desktop:margin-right-5 measure-4">
                <div className="display-flex flex-justify">
                  <div>
                    <small className="text-bold text-uppercase"><SanitizedHTML html={disclaimerMap().disclaimer} /></small>
                    <h2 className="margin-top-1 margin-bottom-4 font-serif-xl"><SanitizedHTML html={disclaimerMap().welcome} /></h2>
                  </div>
                  <Language id={"basic-nav-section-disclaimer"} mode={'light'}/>
                </div>
                <div className="font-body-lg margin-bottom-4"><SanitizedHTML html={disclaimerMap().body} /></div>
                <button className="icor-btn"
                onClick={closeModal}>
                  <SanitizedHTML html={disclaimerMap().button} />
                </button>
            </div>
            <div className="flex-1 order-first desktop:order-initial">
                <SealLarge />
            </div>
        </div>
        
        </div>
    </Modal>
)
}