import React, { useState, useEffect } from "react"
import Modal from 'react-modal';
import SanitizedHTML from 'react-sanitized-html';
import { useStaticQuery, graphql } from 'gatsby';
import searchStyles from './search.module.scss';
import solutionStyles from './solutions.module.scss';
import { useIntl, FormattedMessage  } from "gatsby-plugin-intl"
Modal.setAppElement('#___gatsby')
const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    maxHeight             : '90vh',
    maxWidth              : '90vw',
    fontSize              : '18px',
    backgroundColor       : '#ffffff',
    padding               : '1rem 0 1rem 2rem'
  }
};
Modal.defaultStyles.overlay.backgroundColor = 'rgba(51, 51, 51, 0.77)';
Modal.defaultStyles.content.border = '0';
Modal.defaultStyles.content.borderRadius = '0';
Modal.defaultStyles.content.padding = '0';
Modal.defaultStyles.content.backgroundColor = '#FFFFFF';

export default function Search() {
    const intl = useIntl();
    const [modalIsOpen, setIsOpen] = useState(false);
    const [isSearching, setIsSearching] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const solutionData =  useStaticQuery(graphql`
        query {
          faq {
            fAQ {
                displayText
                answer
                sortOrder
                contentItemId
                topic {
                    contentItems {
                        displayText
                    }
                }
            }
          },
          solutions {
            solutions(first:500) {
              contentItemId
              titleEnglish
              titleSpanish
              titleChinese
              titleHaitianCreole
              titlePortuguese
              titlePunjabi
              bodyEnglish
              bodySpanish
              bodyChinese
              bodyHaitianCreole
              bodyPortuguese
              bodyPunjabi
              children {
                contentItemIds
              }
              glossaryTerms {
                contentItemIds
              }
            }
          }
        }
    `);

    const allowedTags = [
      "address", "article", "aside", "footer", "header", "h1", "h2", "h3", "h4",
      "h5", "h6", "hgroup", "main", "nav", "section", "blockquote", "dd", "div",
      "dl", "dt", "figcaption", "figure", "hr", "li", "main", "ol", "p",
      "ul", "a", "abbr", "b", "bdi", "bdo", "br", "cite", "code", "data", "dfn",
      "em", "i", "kbd", "mark", "q", "rb", "rp", "rt", "rtc", "s", "samp",
      "small", "span", "strong", "sub", "sup", "time", "u", "var", "wbr", "caption",
      "col", "colgroup", "table", "tbody", "td", "tfoot", "th", "thead", "tr"
    ];

    const allowedAttributes = {
      a: [ 'href', 'name', 'target' ],
      '*': ['class'],
      img: [ 'src' ]
    };

    function solutionsMap() {
      switch (intl.locale) {
        case 'es':
          return solutionData.solutions.solutions.map(solution => ({
            contentItemId: solution.contentItemId,
            title: solution.titleSpanish || solution.titleEnglish,
            body: solution.bodySpanish || solution.bodyEnglish,
            children: solution.children?.contentItemIds || []
          }))
        case 'zh':
          return solutionData.solutions.solutions.map(solution => ({
            contentItemId: solution.contentItemId,
            title: solution.titleChinese || solution.titleEnglish,
            body: solution.bodyChinese || solution.bodyEnglish,
            children: solution.children?.contentItemIds || []
          }))
        case 'ht':
          return solutionData.solutions.solutions.map(solution => ({
            contentItemId: solution.contentItemId,
            title: solution.titleHaitianCreole || solution.titleEnglish,
            body: solution.bodyHaitianCreole || solution.bodyEnglish,
            children: solution.children?.contentItemIds || []
          }))
        case 'pt':
          return solutionData.solutions.solutions.map(solution => ({
            contentItemId: solution.contentItemId,
            title: solution.titlePortuguese || solution.titleEnglish,
            body: solution.bodyPortuguese || solution.bodyEnglish,
            children: solution.children?.contentItemIds || []
          }))
        case 'pa':
          return solutionData.solutions.solutions.map(solution => ({
            contentItemId: solution.contentItemId,
            title: solution.titlePunjabi || solution.titleEnglish,
            body: solution.bodyPunjabi || solution.bodyEnglish,
            children: solution.children?.contentItemIds || []
          }))
        default: 
          return solutionData.solutions.solutions.map(solution => ({
            contentItemId: solution.contentItemId,
            title: solution.titleEnglish,
            body: solution.bodyEnglish,
            children: solution.children?.contentItemIds || []
          }))
      }
    }

    function faqsMap() {
      // Adding new languages for faqs later
      switch (intl.locale) {
        default: 
          return solutionData.faq.fAQ.map(faq => ({
            contentItemId: faq.contentItemId,
            title: faq.displayText,
            body: faq.answer
          }))
      }
    }

    function searchTerm(event) {
        event.preventDefault();
        if(searchQuery !== '') {
          setIsOpen(true);
          const searchData = window.location.pathname.includes('faq') ? faqsMap() : solutionsMap();
          const regex = new RegExp("\\b" + searchQuery + "\\b", 'i');
          setSearchResults(
            searchData
              .filter(solution => solution.body !== "")
              .filter(solution => Object.keys(solution)
                .some(key =>
                  // tests value without HTML tags if it contains the string
                  solution[key] ? regex.test(solution[key].toString().replace(/(<([^>]+)>)/gi, "").toLowerCase()) : ''
                ))
          );
        }
        if(document.querySelector('.usa-js-mobile-nav--active')){
          document.querySelector('.usa-js-mobile-nav--active').classList.remove('usa-js-mobile-nav--active');
          document.querySelector('.usa-overlay').classList.remove('is-visible');
          document.querySelector('.usa-nav').classList.remove('is-visible');
        }

    }

    function afterOpenModal() {
      if(document.getElementById('search-results')){
        const regex = new RegExp(searchQuery + "(?![^<>]*(([\/\"']|]]|\b)>))", 'gi');
        document.getElementById('search-results').innerHTML = document.getElementById('search-results').innerHTML.replaceAll(regex, `<span class="bg-yellow">${searchQuery.toUpperCase()}</span>`);
      }
    }
    
    return(
    <div className="flex-align-self-end">
        {!isSearching && 
            <button className={searchStyles["search_display"]} type="submit" onClick={event => {event.preventDefault(); setIsSearching(true);}}>
                <span className="usa-sr-only">Search</span>
            </button>}
        {isSearching && 
            <div className={searchStyles["search_container"]}>
                <div className="usa-search--small grid-row flex-no-wrap">
                    <label className="usa-sr-only" htmlFor="search-field-es-small"> Search </label>
                    <input
                        className={searchStyles["search_input"]}
                        autoFocus
                        id="search-field"
                        type="search"
                        name="search"
                        onChange={() => setSearchQuery(document.querySelector('#search-field').value)}
                        onKeyPress={(event) => event.key === 'Enter' ? searchTerm(event) : ''}
                    />
                    <button className={searchStyles["search_btn"]} type="submit" onClick={(event) => {searchTerm(event)}}>
                        <span className="usa-sr-only">Search</span>
                    </button>
                </div>
            </div>}
        <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        shouldCloseOnOverlayClick={true}
        style={customStyles}
        autoFocus
        contentLabel="Search Results"
        id="search-results-modal">
            { searchResults.length > 0 && 
              <div className="width-desktop maxw-full overflow-auto" style={{height: '80vh'}}>
                <div className="grid-row width-full flex-no-wrap flex-justify flex-align-center position-fixed top-0 left-0 bg-white padding-top-4 padding-bottom-2 padding-left-4 padding-right-6">
                  <h2 className="margin-0">{searchResults.length} results for: {searchQuery}</h2>
                  <button type="button" className={solutionStyles.modalClose} onClick={event => {event.preventDefault(); setIsSearching(false); setIsOpen(false);}}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="14" viewBox="0 0 64 64"><title><FormattedMessage id="headerClose" /></title><path fill="#000000" fillRule="evenodd" d="M57.042 1.15l5.809 5.808a4 4 0 0 1 0 5.657L43.465 32l19.386 19.385a4 4 0 0 1 0 5.657l-5.809 5.809a4 4 0 0 1-5.657 0L32 43.465 12.615 62.851a4 4 0 0 1-5.657 0l-5.809-5.809a4 4 0 0 1 0-5.657L20.535 32 1.149 12.615a4 4 0 0 1 0-5.657l5.809-5.809a4 4 0 0 1 5.657 0L32 20.535 51.385 1.149a4 4 0 0 1 5.657 0z"/></svg>
                  </button>
                </div>
                <ul id="search-results" className={searchStyles["search_results"]}>
                    {searchResults.map(result => {
                        return(<li key={result.contentItemId}>
                          <h3 className="margin-bottom-0">{<SanitizedHTML allowedTags={allowedTags} allowedAttributes={allowedAttributes} html={result.title}/>}</h3>
                          <SanitizedHTML allowedTags={allowedTags} allowedAttributes={allowedAttributes} html={result.body}/>
                        </li>)
                    })}
                </ul>
              </div>
            }
            { searchResults.length <= 0 && 
              <div>
                <div className="grid-row width-full flex-no-wrap flex-justify">
                  <h2 className="margin-bottom-0">{searchResults.length} results for: {searchQuery}</h2>
                  <button type="button" className={solutionStyles.modalClose} onClick={event => {event.preventDefault(); setIsSearching(false); setIsOpen(false);}}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="14" viewBox="0 0 64 64"><title><FormattedMessage id="headerClose" /></title><path fill="#000000" fillRule="evenodd" d="M57.042 1.15l5.809 5.808a4 4 0 0 1 0 5.657L43.465 32l19.386 19.385a4 4 0 0 1 0 5.657l-5.809 5.809a4 4 0 0 1-5.657 0L32 43.465 12.615 62.851a4 4 0 0 1-5.657 0l-5.809-5.809a4 4 0 0 1 0-5.657L20.535 32 1.149 12.615a4 4 0 0 1 0-5.657l5.809-5.809a4 4 0 0 1 5.657 0L32 20.535 51.385 1.149a4 4 0 0 1 5.657 0z"/></svg>
                  </button>
                </div>
                <div className="width-tablet-lg maxw-full">
                  <p>No Results Found</p>
                </div>
              </div>
            }
        </Modal>
    </div>
    );
}