import React from "react"
import { useIntl, FormattedMessage  } from "gatsby-plugin-intl"
import SanitizedHTML from 'react-sanitized-html';
import "./header.scss"
import SEO from "./seo"
import Language from "./language"
import Flag from "./flag"
import Seal from "../images/seal"
import IconDotGov from "../images/icon_dot_gov.svg"
import IconHttps from "../images/icon_https.svg"
import { Link, graphql, useStaticQuery } from "gatsby"
import Search from "./search"

export default function Header({ data }) {
  const intl = useIntl();
  const isFaq = typeof window !== 'undefined' ? window.location.pathname.includes('faq') : false;
  const isResources = typeof window !== 'undefined' ? window.location.pathname.includes('resources') : false;
  const isModelHearing = typeof window !== 'undefined' ? window.location.pathname.includes('model-hearing-program') : false;
  const headerData =  useStaticQuery(graphql`
    query {
      pageTranslations {
        pageTranslations {
          headerBannerLink
          headerBannerOfficialDescription
          headerBannerOfficialLabel
          headerBannerSecureDescription
          headerBannerSecureLabel
          headerBannerText
          headerSkip
          localization {
            culture
          }
        }
      }
    }
  `);

  function headerText() {
    const pageTranslations = headerData.pageTranslations.pageTranslations;
    const english = pageTranslations.filter(page => page.localization.culture === 'en-US').map(header => ({
      link: header.headerBannerLink,
      officialDescription: header.headerBannerOfficialDescription,
      officialLabel: header.headerBannerOfficialLabel,
      secureDescription: header.headerBannerSecureDescription,
      secureLabel: header.headerBannerSecureLabel,
      text: header.headerBannerText
    }))[0];
    switch (intl.locale) {
      case 'es':
        return pageTranslations.filter(page => page.localization.culture === 'es').map(header => ({
          link: header.headerBannerLink,
          officialDescription: header.headerBannerOfficialDescription,
          officialLabel: header.headerBannerOfficialLabel,
          secureDescription: header.headerBannerSecureDescription,
          secureLabel: header.headerBannerSecureLabel,
          text: header.headerBannerText
        }))[0] || english
      case 'zh':
        return pageTranslations.filter(page => page.localization.culture === 'zh').map(header => ({
          link: header.headerBannerLink,
          officialDescription: header.headerBannerOfficialDescription,
          officialLabel: header.headerBannerOfficialLabel,
          secureDescription: header.headerBannerSecureDescription,
          secureLabel: header.headerBannerSecureLabel,
          text: header.headerBannerText
        }))[0] || english
      case 'ht':
        return pageTranslations.filter(page => page.localization.culture === 'fr-HT').map(header => ({
          link: header.headerBannerLink,
          officialDescription: header.headerBannerOfficialDescription,
          officialLabel: header.headerBannerOfficialLabel,
          secureDescription: header.headerBannerSecureDescription,
          secureLabel: header.headerBannerSecureLabel,
          text: header.headerBannerText
        }))[0] || english
      case 'pt':
        return pageTranslations.filter(page => page.localization.culture === 'pt').map(header => ({
          link: header.headerBannerLink,
          officialDescription: header.headerBannerOfficialDescription,
          officialLabel: header.headerBannerOfficialLabel,
          secureDescription: header.headerBannerSecureDescription,
          secureLabel: header.headerBannerSecureLabel,
          text: header.headerBannerText
        }))[0] || english
      case 'pa':
        return pageTranslations.filter(page => page.localization.culture === 'pa').map(header => ({
          link: header.headerBannerLink,
          officialDescription: header.headerBannerOfficialDescription,
          officialLabel: header.headerBannerOfficialLabel,
          secureDescription: header.headerBannerSecureDescription,
          secureLabel: header.headerBannerSecureLabel,
          text: header.headerBannerText
        }))[0] || english
      default: 
        return english
    }
  }
  return (
    <div>
      <SEO
        lang={intl.locale}
        title={intl.formatMessage({ id: "title" })}
        keywords={[`ICOR`, `Immigration Court Online Resource`, `EOIR`, `immigration`]}
      />
      <a className="usa-skipnav" href="#main-content"><FormattedMessage id="headerSkip" /></a>
      <section className="usa-banner" aria-label="Official government website">
        <div className="usa-accordion">
          <header className="usa-banner__header">
            <div className="usa-banner__inner">
              <div className="grid-col-auto margin-right-1">
                <Flag />
              </div>
              <div className="grid-col-fill tablet:grid-col-auto">
                <p className="usa-banner__header-text">
                  {headerText().text}
                </p>
                <p className="usa-banner__header-action" aria-hidden="true">
                  {headerText().link}
                </p>
              </div>
              <button
                className="usa-accordion__button usa-banner__button"
                aria-expanded="false"
                aria-controls="gov-banner"
              >
                <span className="usa-banner__button-text">{headerText().link}</span>
              </button>
            </div>
          </header>
          <div className="usa-banner__content usa-accordion__content" id="gov-banner" hidden>
            <div className="grid-row grid-gap-lg">
              <div className="usa-banner__guidance tablet:grid-col-6">
                <IconDotGov />
                <div className="usa-media-block__body margin-left-2">
                  <div>
                    <strong>{headerText().officialLabel}</strong>
                    <br />
                    <SanitizedHTML html={headerText().officialDescription}/>
                  </div>
                </div>
              </div>
              <div className="usa-banner__guidance tablet:grid-col-6">
                <IconHttps />
                <div className="usa-media-block__body margin-left-2">
                  <div>
                    <strong>{headerText().secureLabel}</strong>
                    <br />
                    <SanitizedHTML html={headerText().secureDescription}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="usa-overlay"></div>
      <header className="usa-header usa-header--basic">
        <div className="usa-nav-container">
          <div className="usa-navbar">
            <div className="usa-logo flex-no-wrap tablet:flex-wrap grid-row flex-align-center" id="extended-logo">
              <Seal />
              <em className="usa-logo__text font-serif-lg tablet:font-serif-2xl margin-left-1 tablet:margin-left-2">
                EOIR
              </em>
              <Link to="/" className="text-no-underline border-left border-white margin-left-1 line-height-mono-2 tablet:margin-left-2 padding-left-1 tablet:padding-left-2 font-serif-3xs tablet:text-ls-2 tablet:font-serif-md">
                <span className="text-white">Immigration Court <br /> Online Resource</span>
              </Link>
            </div>
            <button type="button" className="usa-menu-btn"><FormattedMessage id="headerMenu" /></button>
          </div>
          <nav aria-label="Primary navigation" className="usa-nav tablet:padding-2 tablet:width-mobile">
            <Search/>
            <button type="button" className="usa-nav__close tablet:margin-y-1" style={{order: -1, alignSelf: 'self-end'}}>
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 64 64"><title><FormattedMessage id="headerClose" /></title><path fill="#ffffff" fillRule="evenodd" d="M57.042 1.15l5.809 5.808a4 4 0 0 1 0 5.657L43.465 32l19.386 19.385a4 4 0 0 1 0 5.657l-5.809 5.809a4 4 0 0 1-5.657 0L32 43.465 12.615 62.851a4 4 0 0 1-5.657 0l-5.809-5.809a4 4 0 0 1 0-5.657L20.535 32 1.149 12.615a4 4 0 0 1 0-5.657l5.809-5.809a4 4 0 0 1 5.657 0L32 20.535 51.385 1.149a4 4 0 0 1 5.657 0z"/></svg>
            </button>
            {
              !isFaq && !isResources && !isModelHearing &&
              <Language id={"basic-nav-section-one"}/>
            }
          </nav>
        </div>
      </header>
    </div>
  )
}